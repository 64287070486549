import { useState, useEffect } from 'react';
import './App.css';

function App() {
  const [text, setText] = useState(null);
  const [posts, setPosts] = useState([]);
  const handleSubmit = (event) => {
    const postPost = async () => {
      event.preventDefault();
      const resp = await fetch('/api/posts', {
        method: "POST",
        body: JSON.stringify({text})
      });
      setText(null);
    };
    postPost();
  }

  useEffect(() => {
    const getPosts = async () => {
      const resp = await fetch('/api/posts');
      const postsResp = await resp.json();
      setPosts(postsResp);
    };
    getPosts();
  }, []);

  return (
    <div className="App">
      <h1>Post Service</h1>
      <form onSubmit={handleSubmit}>
        <label>
          Text:
          <input type="text" value={text} onChange={(e) => setText(e.target.value)} />
        </label>
        <input type="submit" value="Submit" />
      </form>
      <hr />
      <div>
        {posts.map((post) => <p>{post.text}</p>)}
      </div>
    </div>
  );
}

export default App;
